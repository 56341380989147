import ReactMarkdown from 'react-markdown'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
// const SyntaxHighlighter = loadable(() => import('./MarkdownRendererComponents/SyntaxHighlighter'))

// import { prism, dracula } from 'react-syntax-highlighter/dist/cjs/styles/prism'
import remarkBreaks from 'remark-breaks'
import { EmojiConvertor } from 'emoji-js'
import rehypeTwemojify from 'rehype-twemojify'
import { useContext, useEffect, useState } from 'react'
import { customEmojiRegex, rehypeCustomEmoji } from './MarkdownRendererComponents/RehypeCustomEmoji'
import loadable from '@loadable/component'
import { useCurrentUser } from '../../utils/hooks/self/useSelfInfo'
import { rehypeLinks } from './MarkdownRendererComponents/RehypeLinks'
import { rehypeIFrames } from './MarkdownRendererComponents/RehypeIFrames'
const ranges = [
  '\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]',
  ' ', // Also allow spaces
].join('|')

const removeEmoji = (str: string) => str.replace(new RegExp(ranges, 'g'), '').replace(customEmojiRegex, '')

const isOnlyEmojis = (str: string) => !removeEmoji(str).length
const smallEmojis = (str: string) =>
  isOnlyEmojis(emojiConverter.replace_colons(str)) &&
  emojiConverter.replace_colons(str).replace(customEmojiRegex, 'a').length < 20
const emojiConverter = new EmojiConvertor()
emojiConverter.replace_mode = 'unified'

const recursiveStringifyNode = (node: any) =>
  node?.props?.children
    ? node?.props?.children.map((v: any) => recursiveStringifyNode(v)).join('')
    : node?.props?.alt ?? node

export const MarkdownRenderer = (props: { children: string; className?: string; destyle?: boolean }) => {
  const [anonymousDark, setAnonymousDark] = useState(null as boolean | null)
  useEffect(() => {
    setAnonymousDark(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
  }, [])
  const user = useCurrentUser()
  // const style = user?.theme || (!user && anonymousDark) ? dracula : prism
  return (
    <ReactMarkdown
      plugins={[remarkGfm]}
      remarkPlugins={[remarkMath, remarkBreaks]}
      rehypePlugins={[
        rehypeKatex,
        [
          rehypeTwemojify,
          {
            className: smallEmojis(props.children) ? 'emoji-large' : 'emoji-small',
            folder: 'svg',
            ext: '.svg',
          },
        ],
        [
          rehypeCustomEmoji,
          {
            className: smallEmojis(props.children) ? 'emoji-large' : 'emoji-small',
          },
        ],
        [
          rehypeIFrames,
          {
            className: 'iframe w-full aspect-video rounded-2xl shadow-lg',
          },
        ],
        [
          rehypeLinks,
          {
            className: 'underline text-primary',
          },
        ],
      ]}
      className={
        !props.destyle
          ? `prose dark:prose dark:prose-invert prose-img:mt-0 prose-img:mb-0 break-words ${props.className ?? ''}`
          : `${props.className ?? ''}`
      }
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '')
          if (Array.isArray(children)) {
            if ((children[0] as any)?.props?.children) children = recursiveStringifyNode(children[0])
            // console.log(children)
          }
          // return match ? (
          //   //@ts-ignore
          //   <SyntaxHighlighter
          //     style={style}
          //     customStyle={{ wordBreak: 'break-word' }}
          //     codeTagProps={{ style: { wordBreak: 'break-word' } }}
          //     language={match[1]}
          //     PreTag="div"
          //     {...props}
          //     wrapLongLines
          //   >
          //     {String(children).replace(/\n$/, '')}
          //   </SyntaxHighlighter>
          // ) :
          return (
            <code className={`${className}`} {...props}>
              {children}
            </code>
          )
        },
        img({ node, className, children, ...props }) {
          return <img className={`${className}`} {...props} referrerPolicy="no-referrer" />
        },
        iframe({ node, className, children, ...props }) {
          let src = props.src?.substring(2, props.src.length - 1)
          if (src?.match(/^https?:\/\/(www\.)?disadus\.app/)) {
            return null
          }
          // enable modest branding on youtube iframe
          if (src?.match(/^https?:\/\/(www\.)?youtube(-no-cookie)?\.com/)) {
            src += '&modestbranding=1'
          }
          return <iframe className={`${className}`} {...props} src={src} allowFullScreen key={src} />
        },
      }}
    >
      {emojiConverter.replace_colons(props.children)}
    </ReactMarkdown>
  )
}
export default MarkdownRenderer
