const colors = require('tailwindcss/colors')
const plugin = require('tailwindcss/plugin')

// Rotate X utilities
const rotateX = plugin(function ({ addUtilities }) {
  addUtilities({
    '.rotate-x-20': {
      transform: 'rotateX(20deg)',
    },
    '.rotate-x-40': {
      transform: 'rotateX(40deg)',
    },
    '.rotate-x-60': {
      transform: 'rotateX(60deg)',
    },
    '.rotate-x-80': {
      transform: 'rotateX(80deg)',
    },
    '.rotate-x-160': {
      transform: 'rotateX(160deg)',
    },
    '.rotate-x-180': {
      transform: 'rotateX(180deg)',
    },
  })
})
module.exports = {
  mode: 'jit',
  purge: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './components/**/**/*.{js,ts,jsx,tsx}',
    './components/**/**/**/*.{js,ts,jsx,tsx}',
    './components/**/**/**/**/*.{js,ts,jsx,tsx}',
  ],
  darkMode: 'class',
  theme: {
    fontFamily: {
      prompt: ['Prompt', 'sans-serif'],
      nunito: ['Nunito', 'sans-serif'],
      roboto: ['Roboto', 'sans-serif'],
      oxygen: ['Oxygen', 'sans-serif'],
      inter: ['Inter', 'sans-serif'],
      shippori: ['Shippori Antique B1', 'sans-serif'],

      varela: ['Varela Round', 'sans-serif'],
      ubuntu: ['Ubuntu', 'sans-serif'],
      // work sans
      wsans: ['Work Sans', 'sans-serif'],
      mono: ['Fira Code', 'monospace'],
      montserrat: ['Montserrat', 'sans-serif'],
    },
    // boxShadow: {
    //   // inner: `box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05)`,
    //   'inner-lg': `box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.1)`,
    //   'inner-xl': `box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.15)`,
    //   'inner-2xl': `box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.2)`,

    // },
    minWidth: {
      screen: '100vw',
      0: '0',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      8: '2rem',
      10: '2.5rem',
      12: '3rem',
      16: '4rem',
      20: '5rem',
      24: '6rem',
      32: '8rem',
      40: '10rem',
      48: '12rem',
      56: '14rem',
      64: '16rem',
      72: '18rem',
      80: '20rem',
      88: '22rem',
      96: '24rem',
      112: '28rem',
      128: '32rem',
      144: '36rem',
      160: '40rem',
      176: '44rem',
      192: '48rem',
      208: '52rem',
      224: '56rem',
      240: '60rem',
      256: '64rem',
      272: '68rem',
      288: '72rem',
      304: '76rem',
      320: '80rem',
      336: '84rem',
      352: '88rem',
      368: '92rem',
      384: '96rem',
      400: '100rem',
      416: '104rem',
      432: '108rem',
      448: '112rem',
      464: '116rem',
      480: '120rem',
      496: '124rem',
      512: '128rem',
    },
    minHeight: {
      screen: '100vh',
      0: '0',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      8: '2rem',
      10: '2.5rem',
      12: '3rem',
      16: '4rem',
      20: '5rem',
      24: '6rem',
      32: '8rem',
      40: '10rem',
      48: '12rem',
      56: '14rem',
      64: '16rem',
      72: '18rem',
      80: '20rem',
      88: '22rem',
      96: '24rem',
      112: '28rem',
      128: '32rem',
      144: '36rem',
      160: '40rem',
      176: '44rem',
      192: '48rem',
      208: '52rem',
      224: '56rem',
      240: '60rem',
      256: '64rem',
      272: '68rem',
      288: '72rem',
      304: '76rem',
      320: '80rem',
      336: '84rem',
      352: '88rem',
      368: '92rem',
      384: '96rem',
      400: '100rem',
      416: '104rem',
      432: '108rem',
      448: '112rem',
      464: '116rem',
      480: '120rem',
      496: '124rem',
      512: '128rem',
    },
    borderRadius: {
      none: '0',
      sm: '.125rem',
      default: '.25rem',
      lg: '0.5rem',
      xl: '0.5rem',
      '2xl': '1rem',
      '3xl': '1.5rem',
      '4xl': '2rem',
      '5xl': '3rem',
      '6xl': '4rem',
      '7xl': '5rem',
      full: '999999px',
    },
    extend: {
      animation: {
        'reverse-spin': 'reverse-spin 1s linear infinite',
        shake: 'shake 1s cubic-bezier(.36,.07,.19,.97) infinite',
        gradient: 'gradient 1s linear infinite',
        'gradient-medium': 'gradient 2s linear infinite',
        'gradient-slow': 'gradient 3s linear infinite',
        'bounce-mini': 'bounce-mini 8s ease-in-out infinite',
      },
      keyframes: {
        'reverse-spin': {
          from: {
            transform: 'rotate(360deg)',
          },
        },
        'bounce-mini': {
          '0%, 100%': {
            transform: 'translateY(0px)',
            // 'animation-timing-function': 'cubic-bezier(0.8, 0, 1, 1)',
          },
          '50%': {
            transform: 'translateY(15px)',
            // 'animation-timing-function': 'cubic-bezier(0, 0, 0.2, 1)',
          },
        },
        shake: {
          '10%, 90%': {
            transform: 'translate3d(-1px, 0, 0)',
          },
          '20%, 80%': {
            transform: 'translate3d(2px, 0, 0)',
          },
          '30%, 50%, 70%': {
            transform: 'translate3d(-4px, 0, 0)',
          },
          '40%, 60%': {
            transform: 'translate3d(4px, 0, 0)',
          },
        },
        gradient: {
          from: {
            'background-size': '200%',
          },
          to: {
            'background-position': '200% center',
            'background-size': '200%',
          },
        },
      },
      colors: {
        gray: {
          ...colors.zinc,
          1000: '#120102',
          850: '#202023',
          750: '#333338',
          650: '#4d4d4f',
          350: '#bbbbc1',
          250: '#dcdce0',
          150: '#ececee',
        },
        primary: {
          DEFAULT: '#3CAFA3',
          50: '#BBE8E3',
          100: '#ABE2DD',
          200: '#8DD8D0',
          300: '#6FCEC4',
          400: '#50C3B8',
          500: '#3CAFA3',
          600: '#319087',
          700: '#27726A',
          800: '#1D544E',
          900: '#123532',
        },
      },
      zIndex: {
        '-10': '-10',
        '-20': '-20',
      },
      transitionDelay: {
        400: '400ms',
        600: '600ms',
      },
      transitionDuration: {
        1200: '1200ms',
      },
      translate: {
        '4/3': '133.33%',
      },
      invert: {
        10: '.10',
        20: '.20',
        25: '.25',
        30: '.30',
        40: '.40',
        50: '.5',
        60: '.60',
        70: '.70',
        75: '.75',
        80: '.80',
        90: '.90',
        95: '.95',
        100: '1',
      },
      width: {
        '487px': '487px',
        'screen/2': '50vw',
        'screen/3': 'calc(100vw / 3)',
        'screen/4': 'calc(100vw / 4)',
        'screen/5': 'calc(100vw / 5)',
        'screen/6': 'calc(100vw / 6)',
        '2screen/3': 'calc(200vw / 3)',
        '3screen/4': 'calc(300vw / 4)',
        '4screen/5': 'calc(400vw / 5)',
        '5screen/6': 'calc(500vw / 6)',
        104: '26rem', // 416px
        112: '28rem', // 448px
        128: '32rem', // 512px
        144: '36rem', // 576px
        160: '40rem', // 640px
        176: '44rem', // 704px
        192: '48rem', // 768px
        208: '52rem', // 832px
        224: '56rem', // 896px
        240: '60rem', // 960px
        256: '64rem', // 1024px
        272: '68rem', // 1088px
        288: '72rem', // 1152px
        304: '76rem', // 1216px
        320: '80rem', // 1280px
        336: '84rem', // 1344px
        352: '88rem', // 1408px
        368: '92rem', // 1472px
        384: '96rem', // 1536px
        400: '100rem', // 1600px
        416: '104rem', // 1664px
        432: '108rem', // 1728px
        448: '112rem', // 1792px
        464: '116rem', // 1856px
        480: '120rem', // 1920px
        496: '124rem', // 1984px
        512: '128rem', // 2048px
      },
      scale: {
        80: '.8',
      },
      height: {
        112: '28rem',
        128: '32rem',
        144: '36rem',
        160: '40rem',
        176: '44rem',
        192: '48rem',
        208: '52rem',
        224: '56rem',
        240: '60rem',
        256: '64rem',
        272: '68rem',
        288: '72rem',
        304: '76rem',
        320: '80rem',
        336: '84rem',
        352: '88rem',
        368: '92rem',
        384: '96rem',
        400: '100rem',
        416: '104rem',
        432: '108rem',
        448: '112rem',
        464: '116rem',
        480: '120rem',
        496: '124rem',
        512: '128rem',

        'screen/2': '50vh',
        'screen/3': 'calc(100vh / 3)',
        'screen/4': 'calc(100vh / 4)',
        'screen/5': 'calc(100vh / 5)',
      },
      maxHeight: {
        112: '28rem',
        128: '32rem',
        144: '36rem',
        160: '40rem',
        176: '44rem',
        192: '48rem',
        208: '52rem',
        224: '56rem',
        240: '60rem',
        256: '64rem',
        272: '68rem',
        288: '72rem',
        304: '76rem',
        320: '80rem',
        336: '84rem',
        352: '88rem',
        368: '92rem',
        384: '96rem',
        400: '100rem',
        416: '104rem',
        432: '108rem',
        448: '112rem',
        464: '116rem',
        480: '120rem',
        496: '124rem',
        512: '128rem',

        'screen/2': '50vh',
        'screen/3': 'calc(100vh / 3)',
        'screen/4': 'calc(100vh / 4)',
        'screen/5': 'calc(100vh / 5)',
      },
      maxWidth: {
        '487px': '487px',
      },
      fontSize: {
        scaled: 'calc(1.1rem + 1vw)',
      },
    },
    screens: {
      inf: { max: '99999999px' },
      //  => @media (max-width: 99999999px) { ... } Literally so i can use a form of !important

      '4xl': { max: '4096px' },
      // => @media (max-width: 4096px) { ... } Wtf is this...
      '3.5xl': { max: '3096px' },

      '3xl': { max: '2048px' },
      // => @media (max-width: 2048px) { ... }

      '2.5xl': { max: '1920px' },
      // => @media (max-width: 1920px) { ... }

      '2xl': { max: '1535px' },
      // => @media (max-width: 1535px) { ... }

      xl: { max: '1279px' },
      // => @media (max-width: 1279px) { ... }

      lg: { max: '1023px' },
      // => @media (max-width: 1023px) { ... }

      md: { max: '767px' },
      // => @media (max-width: 767px) { ... }

      sm: { max: '639px' },
      // => @media (max-width: 639px) { ... }
      xs: { max: '389px' },
      // => @media (max-width: 389px) { ... }
    },
  },
  variants: {
    extend: {},
    scrollbar: ['rounded', 'dark'],
  },
  plugins: [
    require('tailwind-scrollbar'),
    require('tailwindcss-textshadow'),
    require('tailwind-scrollbar-hide'),
    require('@tailwindcss/typography'),
    require('@tailwindcss/line-clamp'),
    rotateX,
  ],
}
