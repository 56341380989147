import EventEmitter from 'events'
import { API_DOMAIN } from '../../constants'
import { getPersistentStore } from '../../PersistentStore'
import { CourseAnnouncementDataEvent } from '../../types/courseDataTypes'
import { Announcement } from '../../types/courseTypes'
import { fetcher } from '../Fetcher'
import { SocketManager } from '../SocketManager'

export class CourseAnnouncementsDataClass extends EventEmitter {
  constructor(courseID: string) {
    super()
    this.courseID = courseID
    this.init()
  }
  static _instanceMap = new Map<string, CourseAnnouncementsDataClass>()
  static getInstance(courseID: string) {
    if (!CourseAnnouncementsDataClass._instanceMap.has(courseID)) {
      CourseAnnouncementsDataClass._instanceMap.set(courseID, new CourseAnnouncementsDataClass(courseID))
    }
    return CourseAnnouncementsDataClass._instanceMap.get(courseID)!
  }
  courseID: string
  announcements: Announcement[] = []
  fresh?: boolean
  async init() {
    this.loadCachedAnnouncements()
    this.getAnnouncements()
    SocketManager.getInstance().on(`cachedCourseAnnouncementsResponse`, this.onAnnoucementsData.bind(this))
    SocketManager.getInstance().on(`courseAnnouncementsResponse`, this.onAnnoucementsData.bind(this))
  }
  async onAnnoucementsData(announcement: {
    announcements: Announcement[] | null
    courseID: string
    error?: string
    cached: boolean
  }) {
    if (announcement.courseID !== this.courseID) return
    if (announcement.error) return
    this.announcements = announcement.announcements!
    if (!announcement.cached) {
      this.fresh = true
    }
    // console.log('announcements', this.announcements)
    this.emit('announcementsUpdate', {
      announcements: this.announcements,
      cached: !!announcement.cached,
    } as CourseAnnouncementDataEvent)
    await (await getPersistentStore).setItem(`course.${this.courseID}.announcements`, this.announcements)
  }
  async getAnnouncements(fresh: boolean = false) {
    if (localStorage.getItem('token')) return this.getAnnouncementsSocket(fresh)
    return this.getAnnouncementsREST(fresh)
  }
  private async getAnnouncementsSocket(fresh: boolean) {
    if (fresh) {
      SocketManager.getInstance().socket.emit('course.announcements', this.courseID)
      return
    }
    SocketManager.getInstance().socket.emit('cached.course.announcements', this.courseID)
  }
  private async getAnnouncementsREST(fresh: boolean) {
    const response = await fetcher(
      `${API_DOMAIN}/courses/${this.courseID}/announcements${fresh ? '?fresh=true' : ''}`,
      undefined,
      fresh ? `important` : `default`
    )
    const data = await response.json()
    this.announcements = data.announcements
    if (fresh) {
      this.fresh = true
    }
    this.emit('announcementsUpdate', {
      announcements: this.announcements,
      cached: false,
    } as CourseAnnouncementDataEvent)
    await (await getPersistentStore).setItem(`course.${this.courseID}.announcements`, this.announcements)
  }

  async loadCachedAnnouncements() {
    const announcements = await (await getPersistentStore).getItem(`course.${this.courseID}.announcements`)
    if (announcements) {
      this.announcements = announcements as Announcement[]
      this.emit('announcementsUpdate', {
        announcements: this.announcements,
        cached: true,
      } as CourseAnnouncementDataEvent)
    }
  }
}
